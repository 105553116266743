import React from 'react';
import img1 from "./Galleryimg/img1.webp";
import img8 from "./Galleryimg/img8.webp";
import img9 from "./Cardimg/personalprgram.webp";
import img2 from "./Galleryimg/img9.webp";
import { imgData } from './ProgramData';
import GalleryCarousel from './GalleryCarousel';
import Card2 from './Card2';
import "./Gallery.css";
import { Carousel, Image } from 'antd';

const filterImg = imgData.filter(item => item.Type === "imgdata");
const contentStyle = {
  color: '#fff',
  textAlign: 'center',
};

const Gallery = () => (
  <>
    <Carousel className='w-75' autoplay>
      <div>
        <h3 style={contentStyle}> <img src={img1} className="d-block w-100 img" alt="..." /></h3>
      </div>
      <div>
        <h3 style={contentStyle}> <img src={img2} className="d-block w-100 img" alt="..." /></h3>
      </div>
      <div>
        <h3 style={contentStyle}> <img src={img9} className="d-block w-100 img" alt="..." /></h3>
      </div>
      <div>
        <h3 style={contentStyle}> <img src={img2} className="d-block w-100 img" alt="..." /></h3>
      </div>
    </Carousel>
    <div className='gallery-map'>
      {filterImg?.map((item, index) => (
        <div className='box' key={index}>
          <Image
            // width={400}
            src={item?.img}
          />
        </div>
      ))}
    </div>
  </>
);

export default Gallery;
