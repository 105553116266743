import React from 'react'
import img1 from './Bannerimg/Main.jpeg'
import img2 from './Bannerimg/whykalpit.jpeg'
import img3 from './Bannerimg/teacher.jpeg'
import img4 from  './Bannerimg/commingsoon.jpeg'
import img5 from  './Bannerimg/plan.jpeg'
import './Banner.css'

export default function Banner() {
  return (
    <div>
          <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
              <ol className="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              </ol>
              <div className="carousel-inner">
                  <div className="carousel-item active">
                      <img src={img1} className="d-block w-100" alt="..."/>
                  </div>
                  <div className="carousel-item">
                      <img src={img2}className="d-block w-100" alt="..."/>
                  </div>
                  <div className="carousel-item">
                      <img src={img3} className="d-block w-100" alt="..."/>
                  </div>
                  <div className="carousel-item">
                      <img src={img5} className="d-block w-100" alt="..."/>
                  </div>
                  <div className="carousel-item">
                      <img src={img4} className="d-block w-100" alt="..."/>
                  </div>
              </div>
              <button className="carousel-control-prev banner-btn" type="button" data-target="#carouselExampleIndicators" data-slide="prev">
                  <span className="carousel-control-prev-icon " aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
              </button>
              <button className="carousel-control-next banner-btn" type="button" data-target="#carouselExampleIndicators" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
              </button>
          </div>
    </div>
  )
}
